<template>
  <div class="box">
    <div class="downloadList">
      <div class="id">Windows 1.0版本</div>
      <div class="downloadBtn" @click="openDownload(0)">ScratchJr-1.3.2</div>
    </div>
    <div class="downloadList">
      <div class="id">Windows 2.0版本</div>
      <div class="downloadBtn" @click="openDownload(1)">点击下载</div>
      <div class="downloadBtn" @click="openDownload(2)">无法安装解决方案</div>
    </div>
    <div class="downloadList">
      <div class="id">Windows 3.0版本</div>
      <div class="downloadBtn" @click="openDownload(3)">Scratch Setup 3.4.0</div>
      <div class="downloadBtn" @click="openDownload(4)">Scratch Setup 3.9.0</div>
    </div>
    <div class="downloadList">
      <div class="id">Mac 版本</div>
      <div class="downloadBtn" @click="openDownload(5)">Scratch3.0 Mac版</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      arr: [
        "1.0正版/ScratchJr-1.3.2.Setup",
        "2.0正版/461（先安装）",
        "2.0正版/461装完打不开再装这个",
        "3.0正版/Scratch Desktop Setup 3.4.0",
        "3.0正版/Scratch Desktop Setup 3.9.0",
        "Mac版2.0 3.0/Scratch3.0 Mac版",
      ],
    };
  },
  methods: {
    openDownload(val) {
      if (val === 5) {
        window.open(`https://xiaoguoai.cn/versions/${this.arr[val]}.zip`);
        return;
      }
      window.open(`https://xiaoguoai.cn/versions/${this.arr[val]}.exe`);
    },
  },
};
</script>

<style scoped>
.box {
  margin-top: 77px;
  height: 600px;
  background: #f7f7f7;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 100px 200px;
}
.downloadList {
  font-size: 22px;
  background: rgba(0, 0, 0, 0.05);
  width: 300px;
  height: 400px;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 5px;
  padding-left: 20px;
  box-sizing: border-box;
}
.downloadBtn {
  margin: 0 auto;
  margin-bottom: 20px;
  width: 170px;
  height: 50px;
  background: #ccc;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
}
.downloadBtn:hover {
  color: #fff;
  background: #1472ff;
  cursor: pointer;
}
.id {
  width: 170px;
  height: 50px;
  text-align: center;
  margin: 0 auto;
  line-height: 50px;
  border-radius: 5px;
  margin-top: 85px;
  margin-bottom: 55px;
}
</style>
